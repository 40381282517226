import { ClerkProvider } from "@clerk/nextjs"
import Router from "next/router"
import { VercelToolbar } from "@vercel/toolbar/next"

import { ToastContainer } from "react-toastify"
import amplitude from "amplitude-js"
import NProgress from "nprogress"
import { useEffect } from "react"
import Modal from "react-modal"
import { Provider } from "react-redux"
import type { AppProps } from "next/app"
import analytics from "helpers/analytics"
import { store } from "../state/store"

import "react-toastify/dist/ReactToastify.css"
import "../styles/normalize.css"
import "../styles/global.css"
import "../styles/datepicker.css"
import "../styles/nprogress.css"
import "../styles/typography.css"

Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", (path) => {
  NProgress.done()
  analytics.page(path)
})
Router.events.on("routeChangeError", () => NProgress.done())

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.5)"
Modal.setAppElement("#__next")

function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (process.env.AMPLITUDE_API_KEY) {
      amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY, null, {
        includeGclid: true,
        includeFbclid: true,
        includeUtm: true,
        includeReferrer: true,
        apiEndpoint: "5newo57216.execute-api.us-east-1.amazonaws.com/production/proxy-amplitude",
      })

      amplitude.getInstance().logEvent("Loaded a Page", { path: window.location.pathname })
    }
  }, [])
  return (
    <ClerkProvider
      appearance={{
        variables: {
          fontFamily: '"Ginto", sans-serif',
        },
      }}
      {...pageProps}
    >
      <Provider store={store}>
        <ToastContainer position="top-center" limit={5} hideProgressBar={true} />
        <Component {...pageProps} />
        {process.env.NODE_ENV === "development" && process.env.NEXT_PUBLIC_APP_ENV !== "integration" && (
          <VercelToolbar />
        )}
      </Provider>
    </ClerkProvider>
  )
}

export default App
