import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/nextjs"
import { TradeStatus } from "state/types"
import * as api from "./tradingHistoryAPI"
import { TradingHistory } from "./types"

export const fetchTradingHistory = createAsyncThunk("tradingHisotry/fetchTradingHistory", api.getTradingHistory)

const initialState: TradingHistory = {
  acatsTransfers: [],
  tradingDays: [],
  vestTrades: [],
  userJoinedDate: null,
  isLoading: false,
}

export const tradingHistorySlice = createSlice({
  name: "tradingHistory",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradingHistory.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTradingHistory.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          const { acatsTransfers, tradingDays, vestTrades, userJoinedDate } = payload
          state = {
            acatsTransfers,
            tradingDays: tradingDays.map((day) => ({
              tradeDay: day.tradeDay,
              settlementDate: day.settlementDate,
            })),
            vestTrades: vestTrades.map((trade) => ({
              ...trade,
              status: trade.status as TradeStatus,
              notionalValue: parseFloat(trade.notionalValue) || 0,
            })),
            userJoinedDate: userJoinedDate || null,
            isLoading: false,
          }
        }
        state.isLoading = false
        return state
      })
      .addCase(fetchTradingHistory.rejected, (state, action) => {
        Sentry.captureException("Failed to fetch wealth application", {
          extra: {
            error: action.error,
          },
        })
        state.isLoading = false
      })
  },
})

export default tradingHistorySlice.reducer
