/* eslint-disable security/detect-object-injection */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { hashBlackout } from "helpers/blackoutHelper"

interface SpecialBlackoutListState {
  entries: string[][]
  encryptableColumns: string[]
  encryptedEntries: string[]
  fileName: string
  isEncrypted: boolean
}

const initialState: SpecialBlackoutListState = {
  entries: [],
  encryptableColumns: [],
  encryptedEntries: [],
  isEncrypted: false,
  fileName: null,
}

export const specialBlackoutListSlice = createSlice({
  name: "specialBlackoutList",
  initialState,
  reducers: {
    setEntries: (state, action: PayloadAction<{ entries: string[][]; fileName: string }>) => {
      state.entries = action.payload.entries
      state.fileName = action.payload.fileName
    },
    encryptEntries: (state) => {
      state.isEncrypted = true
      const hashedEntries: string[] = []

      for (let i = 0; i < state.entries[0].length; i++) {
        const columnName = state.entries[0][i]
        if (state.encryptableColumns.includes(columnName)) {
          for (let j = 1; j < state.entries.length; j++) {
            const entry = state.entries[j][i]
            hashedEntries.push(hashBlackout(entry))
          }
        }
      }

      state.encryptedEntries = hashedEntries
    },
    clearState: (state) => {
      state.entries = []
      state.isEncrypted = false
      state.encryptableColumns = []
      state.fileName = null
    },
    setEncryptableColumns: (state, action: PayloadAction<string[]>) => {
      state.encryptableColumns = action.payload
    },
    setFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload
    },
  },
})

export const { setEntries, encryptEntries, clearState, setEncryptableColumns, setFileName } =
  specialBlackoutListSlice.actions

export default specialBlackoutListSlice.reducer
