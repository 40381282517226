import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/nextjs"
import { Brokerage } from "state/types"
import analytics from "helpers/analytics"
import { CompanyState } from "./types"
import * as api from "./companyAPI"

export const fetchCompany = createAsyncThunk("company/fetchCompany", api.fetchCompany)

const initialState: CompanyState = {
  brokerageRelationships: [],
  washSaleEnabled: false,
  disconnectedBrokerageFlowEnabled: false,
  ticker: "",
}

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompany.fulfilled, (state, action) => {
        analytics.identify({ is_eligible_for_brokerageless: action.payload.disconnectedBrokerageFlowEnabled })

        return {
          brokerageRelationships: action.payload.brokerageRelationships as Brokerage[],
          ticker: action.payload.ticker,
          washSaleEnabled: action.payload.washSaleEnabled,
          disconnectedBrokerageFlowEnabled: action.payload.disconnectedBrokerageFlowEnabled,
        }
      })
      .addCase(fetchCompany.rejected, (_, action) => {
        Sentry.captureException(`Failed to fetch company brokerages: ${action.error}`)
      })
  },
})

export default companySlice.reducer
