import { configureStore } from "@reduxjs/toolkit"
import onboardingQuizReducer from "./features/onboardingQuiz/onboardingQuizSlice"
import companyReducer from "./features/company/companySlice"
import onboardingBrokerageReducer from "./features/onboardingBrokerage/onboardingBrokerageSlice"
import onboardingRecommendationsReducer from "./features/onboardingRecommendations/onboardingRecommendationsSlice"
import specialBlackoutListReducer from "./features/specialBlackoutList/specialBlackoutListSlice"
import tradingCalendarReducer from "./features/tradingHistory/tradingHistorySlice"

export const store = configureStore({
  reducer: {
    /** Holds answers for the quiz questions */
    onboardingQuiz: onboardingQuizReducer,
    /** Holds company information */
    company: companyReducer,
    /** Holds brokerage information */
    onboardingBrokerage: onboardingBrokerageReducer,
    /**  Holds recommendations information */
    onboardingRecommendations: onboardingRecommendationsReducer,
    /** Holds trading calendar information */
    tradingCalendar: tradingCalendarReducer,
    /** Holds new special blackout list data */
    specialBlackoutList: specialBlackoutListReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
