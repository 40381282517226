import amplitude from "amplitude-js"
import { SimulationResult } from "candor-common"
import { WealthRecommendations } from "helpers/recommendationsHelper"
import { FetchOnboardingRecommendationsParams } from "./types"

interface RecommendationsResponse {
  recommendations: WealthRecommendations
  monteCarloSimulation: SimulationResult
}

export const fetchRecommendations = async (
  params: FetchOnboardingRecommendationsParams
): Promise<RecommendationsResponse> => {
  let amplitude_device_id

  if (amplitude && process.env.AMPLITUDE_API_KEY) {
    amplitude_device_id = amplitude.getInstance().options.deviceId
  }

  const responsePromise = fetch(`/api/dashboard/recommendations`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...params,
      ...(amplitude_device_id && { amplitude_device_id }),
    }),
  })

  const monteCarloResponsePromise = fetch(`/api/onboarding/monte-carlo-simulation`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      goalsAnswers: params.goalsAnswers,
    }),
  })

  const [response, monteCarloResponse] = await Promise.all([responsePromise, monteCarloResponsePromise])
  const [recommendationsResp, monteCarloResponseData] = await Promise.all([response.json(), monteCarloResponse.json()])

  return {
    recommendations: recommendationsResp?.recommendations,
    monteCarloSimulation: monteCarloResponseData?.data,
  }
}
