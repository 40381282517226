import { createHash } from "crypto"

const getNormalizedEmail = (email: string): string => {
  const toLowerCase = email.toLowerCase()
  // Split the email into local part and domain part
  const [localPart, domainPart] = toLowerCase.split("@")

  // Remove the tag from the local part
  const normalizedLocalPart = localPart.split("+")[0]

  // Return the normalized email
  return `${normalizedLocalPart}@${domainPart}`
}

const getNormalizedName = (name: string): string => {
  return name
    .split(/,| |-/)
    .filter((e) => e)
    .map((e) =>
      e
        .normalize("NFD")
        .toLowerCase()
        .replace(/\p{Diacritic}/gu, "")
    )
    .sort()
    .join(" ")
}

export function hashBlackout(userNameOrEmail: string): string {
  const normalize = userNameOrEmail.includes("@")
    ? getNormalizedEmail(userNameOrEmail)
    : getNormalizedName(userNameOrEmail)
  return createHash("sha256").update(normalize).digest("hex")
}
