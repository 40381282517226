import * as Sentry from "@sentry/nextjs"
import { toast } from "react-toastify"

interface Props {
  error?: { message: string } | Error | string
  message?: string
  details?: any
}

export const showErrorMessageAndLogToSentry = ({ error = null, message = "", details = null }: Props) => {
  toast.error(message ? `${message} - please contact hello@candor.co` : "Please contact hello@candor.co")

  if (message && message.length > 0) {
    if (details) Sentry.captureMessage(message, { extra: { details }, level: error ? "error" : "log" })
    else Sentry.captureMessage(message, error ? "error" : "log")
  } else {
    if (typeof error === "string") error = new Error(error)
    if (details) Sentry.captureException(error, { extra: { details } })
    else Sentry.captureException(error)
  }

  return error
}

export const logErrorForEng = ({
  error,
  message,
  details,
}: {
  error?: Error | string
  message: string
  details?: any
}) => {
  if (message) {
    toast.error(message + " - please contact eng@candor.co")
  }

  if (typeof error === "string") {
    error = new Error(error)
  }
  Sentry.captureException(error, { extra: { message, details } })
}
