import { CompanyResponse } from "./types"

const BASE_URL = "/api/onboarding/company"

export const fetchCompany = async (company_id: string): Promise<CompanyResponse | null> => {
  if (!company_id) {
    return null
  }
  const fetchUrl = `${BASE_URL}/` + encodeURIComponent(company_id)
  const response = await fetch(fetchUrl, {
    method: "GET",
  })
  const { data } = await response.json()
  return data || null
}
