import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/nextjs"
import { Brokerage } from "state/types"
import analytics from "helpers/analytics"
import * as api from "./onboardingQuizAPI"
import type {
  LookingToSell,
  BonusInvest,
  Company,
  CurrentlyHaveRSUs,
  QuizState,
  SelectedGoal,
  CostOfLiving,
  SoldRSUsBehaviour,
  CurrentRSUsBehaviour,
} from "./types"

export const saveAnswers = createAsyncThunk("onboardingQuiz/persist", api.saveAnswers)

export const fetchAnswers = createAsyncThunk("onboardingQuiz/fetch", api.fetchAnswers)

const initialState: QuizState = {
  quizAnswers: {
    furthestStep: 1,
    totalSteps: 8,
  },
  goalsAnswers: {
    furthestStep: 1,
    totalSteps: 6,
    monthlyExpenses: 2000,
    housingExpenses: 4000,
    savings: 0,
    selectedGoal: null,
    costOfLiving: null,
    age: 30,
    incomeCash: 100_000,
    incomeRSUs: 50_000,
  },
  furthestStep: 1,
  totalSteps: 13,
  isLoading: false,
  isQuizAnswersLoaded: false,
}

export const onboardingQuizSlice = createSlice({
  name: "onboardingQuiz",
  initialState,
  reducers: {
    setLookingToSell: (state, action: PayloadAction<LookingToSell>) => {
      state.quizAnswers.lookingToSell = action.payload
    },
    setCurrentlyHaveRSUs: (state, action: PayloadAction<CurrentlyHaveRSUs>) => {
      state.quizAnswers.currentlyRSUs = action.payload
    },
    setSoldRSUsBehaviour: (state, action: PayloadAction<SoldRSUsBehaviour>) => {
      state.quizAnswers.soldRSUsBehaviour = action.payload
    },
    setCurrentRSUsBehaviour: (state, action: PayloadAction<CurrentRSUsBehaviour>) => {
      state.quizAnswers.currentRSUsBehaviour = action.payload
    },
    setRSUsPerYear: (state, action: PayloadAction<number>) => {
      state.quizAnswers.RSUsPerYear = action.payload
    },
    setVestedRSUs: (state, action: PayloadAction<number>) => {
      state.quizAnswers.vestedRSUs = action.payload
    },
    setBonusInvest: (state, action: PayloadAction<BonusInvest>) => {
      state.quizAnswers.bonusInvest = action.payload
    },
    setBrokerage: (state, action: PayloadAction<Brokerage>) => {
      analytics.identify({ brokerage: action.payload })
      state.quizAnswers.brokerage = action.payload
    },
    setCompany: (state, action: PayloadAction<Company>) => {
      analytics.identify({ company: action.payload })
      state.quizAnswers.selectedCompany = action.payload
    },
    setQuizFurthestStep: (state, action: PayloadAction<number>) => {
      state.quizAnswers.furthestStep = action.payload
      api.saveAnswers(state)
    },
    setGoalsFurthestStep: (state, action: PayloadAction<number>) => {
      state.goalsAnswers.furthestStep = action.payload
      api.saveAnswers(state)
    },
    setFurthestStep: (state, action: PayloadAction<number>) => {
      state.furthestStep = action.payload
      api.saveAnswers(state)
    },
    setTotalSteps: (state, action: PayloadAction<number>) => {
      state.totalSteps = action.payload
    },
    setQuizTotalSteps: (state, action: PayloadAction<number>) => {
      state.quizAnswers.totalSteps = action.payload
    },
    setGoalsTotalSteps: (state, action: PayloadAction<number>) => {
      state.goalsAnswers.totalSteps = action.payload
    },
    setSelectedGoal: (state, action: PayloadAction<SelectedGoal>) => {
      state.goalsAnswers.selectedGoal = action.payload
    },
    setCostOfLiving: (state, action: PayloadAction<CostOfLiving>) => {
      state.goalsAnswers.costOfLiving = action.payload
    },
    setMonthlyExpenses: (state, action: PayloadAction<number>) => {
      state.goalsAnswers.monthlyExpenses = action.payload
    },
    setHousingExpenses: (state, action: PayloadAction<number>) => {
      state.goalsAnswers.housingExpenses = action.payload
    },
    setAge: (state, action: PayloadAction<number>) => {
      state.goalsAnswers.age = action.payload
    },
    setSavings: (state, action: PayloadAction<number>) => {
      state.goalsAnswers.savings = action.payload
    },
    setIncomeCash: (state, action: PayloadAction<number>) => {
      state.goalsAnswers.incomeCash = action.payload
    },
    setIncomeRSUs: (state, action: PayloadAction<number>) => {
      state.goalsAnswers.incomeRSUs = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnswers.pending, () => {
        return {
          ...initialState,
          isLoading: true,
        }
      })
      .addCase(fetchAnswers.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state = payload
        }
        state.isLoading = false
        state.isQuizAnswersLoaded = true
        return state
      })
      .addCase(fetchAnswers.rejected, (state, action) => {
        state.isLoading = false
        state.isQuizAnswersLoaded = true
        Sentry.captureException(`Failed to fetch quiz answers: ${action.error}`)
      })
  },
})

export const {
  setLookingToSell,
  setCurrentlyHaveRSUs,
  setSoldRSUsBehaviour,
  setCurrentRSUsBehaviour,
  setQuizFurthestStep,
  setGoalsFurthestStep,
  setBonusInvest,
  setBrokerage,
  setRSUsPerYear,
  setVestedRSUs,
  setFurthestStep,
  setCompany,
  setQuizTotalSteps,
  setGoalsTotalSteps,
  setTotalSteps,
  setSelectedGoal,
  setCostOfLiving,
  setMonthlyExpenses,
  setHousingExpenses,
  setAge,
  setSavings,
  setIncomeCash,
  setIncomeRSUs,
} = onboardingQuizSlice.actions

export default onboardingQuizSlice.reducer
