import { GoalsAnswers, QuizAnswers, QuizState } from "./types"
import { Strategy } from "../onboardingRecommendations/types"

export const saveAnswers = (answers: QuizState) => {
  return fetch(`/api/onboarding/quizAnswers`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: answers,
    }),
  })
}

export const fetchAnswers = async (): Promise<QuizState | null> => {
  const response = await fetch(`/api/onboarding/quizAnswers`, {
    method: "GET",
  })
  const { data = {} } = await response.json()
  if (Object.keys(data).length) {
    return data
  }
  return null
}

export const saveChartImage = (
  imageSource: string,
  goalsAnswers: Partial<GoalsAnswers>,
  quizAnswers: Partial<QuizAnswers>,
  strategy: Partial<Strategy>
) => {
  return fetch(`/api/onboarding/save-chart-image`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      imageSource,
      goalsAnswers,
      quizAnswers,
      strategy,
    }),
  })
}
